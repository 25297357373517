const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://movilexpresscompe.com' : 'http://192.168.100.106:3045',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://movilexpresscompe.com' : 'http://192.168.100.106:3045',
    api: 'api/',
    apiSocket: 'movilexpress::1.2',
    nameDir: 'movilexpress',
    package: 'app.dvgeo.movilexpress.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyDZ-1faXgFbPa2zbUFf9_fUyOCxC2aNRrg',
    appName: 'Movil Express',
    provider: '',
    colorPrimary: '#0097D9',
    colorDark: '#0F62AC',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'com.movilexpresspe.passenger',
    playStoreDriverId: 'com.movilexpresspe.driver',
    appStorePassengerId: '6504370730',
    appStoreDriverId: '6504370732',
    email: "roosveth.avila@gmail.com",
};
export default config;
